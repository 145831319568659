import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AboutMe from './components/AboutMe';
const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  
  {
    path: "aboutme",
    element: <AboutMe />,
  },
  {
    path: "*",
    element: (
      <App />
    ),
  },
]);



root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
